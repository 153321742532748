import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import HqimInUseChart from '../charts/chart_hqim_in_use';
import StudentsEngagedChart from '../charts/chart_students_engaged';
import AvgStudentEngagementChart from '../charts/chart_avg_student_engagement';
import SchoolNameChart from '../charts/chart_schoolname';
import HeatMaps from '../heatmaps/heatmaps';
import { Grid, Typography } from '@mui/material';
import AdmNodeDrawer from '../../../../components/care/reports/adm_node_drawer';
import { unionAdmTrees } from '../compare/utils';
import { isHqimAnalysisIncluded, isRacialDiversityAnalysisIncluded, isReportDisplaySummaryOnly, isShowAdmNumbers } from '../../shared/utils';

const ObservationsContent = ({
  observations,
  surveys,
  base_url,
  initialGroupBy,
  leads,
  recipients,
  hideClassRoomEngagement,
  filtersMap,
  ...props
}) => {
  const gridItemStyle = {
    minWidth: '160px !important',
    height: '300px !important',
    display: 'flex',
    alignItems: 'center',
  };

  const observationCountMessage = `Observations: ${observations.length}`;
  const [drawerAdmNode, setDrawerAdmNode] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const showHqim = surveys.every(isHqimAnalysisIncluded);
  const showSummaryOnly = surveys.every(isReportDisplaySummaryOnly);
  const showAdmNumbers = surveys.every(isShowAdmNumbers);

  const mergedAdmTree = useMemo(() => {
    return unionAdmTrees(surveys.map((survey) => survey.adm_tree));
  }, [observations]);

  return (
    <>
      <Grid container spacing={2} wrap="wrap">
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>{observationCountMessage}</strong>
          </Typography>
        </Grid>
        {!hideClassRoomEngagement &&
          (showHqim ? (
            <>
              <Grid item xs={12} sm={12} md={4} sx={{ gridItemStyle }}>
                <HqimInUseChart observations={observations} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ gridItemStyle }}>
                <StudentsEngagedChart observations={observations} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ gridItemStyle }}>
                <AvgStudentEngagementChart observations={observations} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={4} sx={{ gridItemStyle }}>
                <SchoolNameChart observations={observations} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ gridItemStyle }}>
                <StudentsEngagedChart observations={observations} />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ gridItemStyle }}>
                <AvgStudentEngagementChart observations={observations} />
              </Grid>
            </>
          ))}
      </Grid>
      <HeatMaps
        surveyTypeCategoryName={'TODO!!!'}
        admTree={mergedAdmTree}
        observations={observations}
        setDrawerAdmNode={(node) => {
          setDrawerAdmNode(node);
          setIsDrawerOpen(true);
        }}
        showHqimControls={showHqim}
        showSummaryOnly={showSummaryOnly}
        showAdmNumbers={showAdmNumbers}
        initialGroupBy={initialGroupBy}
        filtersMap={filtersMap}
      />
      <AdmNodeDrawer
        surveys={surveys}
        admNode={drawerAdmNode}
        isDrawerOpen={isDrawerOpen}
        observations={observations}
        setIsDrawerOpen={setIsDrawerOpen}
        baseUrl={base_url}
        leads={leads}
        recipients={recipients}
        showSummaryOnly={showSummaryOnly}
        observablesLabel={props.observablesLabel}
      />
    </>
  );
};

ObservationsContent.propTypes = {
  observations: PropTypes.arrayOf(PropTypes.object),
  baseUrl: PropTypes.string.isRequired,
};

export default ObservationsContent;
